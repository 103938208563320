// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: "Home",
  // ChauffeurTitle:
  //   "Chauffeur App | Shopping and Delivery Platform UAE | Chauffeur hub",
  ChauffeurTitle:
    "Best Delivery App in UAE | Fast & Affordable Service | Chauffeur",
  ChauffeurDescription:
    "Experience fast and affordable delivery services in UAE. Use the chauffeur hub best app for reliable, on-demand service at lowest cost across the UAE! ",
  aboutUs: "about Chauffeur",
  send: "send",
  follow: "Follow our news",
  followtitle: " Follow the latest news by subscribing to our newsletter",
  typeemail: "Type your email",
  thankyou: "Thank You",
  thansuccsess: "Your application was successfully submitted.",
  thancontact: "We will look into this and get back to you sooner.",
  thanorderedSuccess: " Your Payment received successfully for the order",
  thanorderSuccessbtn: "Proceed to order",
  thannewsletterSuccess: "Newsletter has been subscribed to your Email",
  thanletterSuccessbtn: "Proceed to Homepage",
  blogsTitle: "Chauffeur hub Blogs | Professional delivery services UAE",
  blogs: "blogs",
  NewPassword: "New Password",
  Message: "Message",
  NotFound: "404: Page Not Found",
  NotFoundTitle: " We couldn't find the page you are looking for.",
  NotFoundText: " Here are some options to get back to proper browsing:",
  NotFoundText2: "Back to Homepage ",
  NotFoundText3: "Contact us for assistance",
  slidertitle1: "What our clients say about Chauffeur",
  slidertitle2:
    "An application that is easy to use, secure, and provides all services quickly and extreme accuracy",
  videotitle:
    "Chauffeur is your professional assistant for everything related to shipping and delivery services",
  videotitle2:
    " The UAE's first app that provides you with accurate and reliable shipping and delivery services in the fastest and most cost-effective way.",
  ComplaintSuggestions: "Complaints & Suggestions",
  Createaccount: "Create account",
  OrSignup: "Or sign up",
  Signup: "Sign up",
  Orlogin: " or login",
  Login: "Login",
  Editprofile: "Edit profile",
  Signin: "Login",
  EmailAddress: "Email Address",
  Password: "Password",
  Account: "Account",
  done: "Done",
  deleteMsg:
    "Deleting your account is final and irreversible. All your data will be erased from our systems",
  settings: "Settings",
  deleteAccount: "Delete the Account",
  AppleStore: "Apple Store",
  GooglePlay: "Google Play",
  AppGallery: "App Gallery",
  Downloadfrom: "Download app from",
  DeleteConfirm:
    " To confirm, please enter your account phone number below. This helps us make sure that you are the one deleting your account.",
  Pleaseentertransferamount: " Please enter the transfer amount",
  transferamount: "transfer amount",
  ChauffeurCardNumber: "Chauffeur Card Number",
  ChauffeurCardNumberwallet:
    " Please enter your Chauffeur card number to top up your wallet",
  inputChauffeurCardNumber: " Please enter your Chauffeur card number",
  ChargeWallet: "Charge Wallet",
  SendMoney: " Send Credit",
  WriteComment: " Write a comment",
  WriteCancelationReason: " Type the reason for cancellation",
  NoBalancePleaseChargeYourWallet: " No Balance Please Charge Your Wallet",
  Password8characters: "Password must be greater than 8 characters",
  Password20characters: "Password must be less than 20 characters",
  TelephoneNumber: "Phone number",
  Phonenumber: " Phone number",
  submit: "Submit",
  "CancelationReason[SD1]": " Reason for cancellation ",
  RateThisDelivery: " Rate this delivery service",
  Ratedelivery: "Delivery Service Rating",
  SendVerificationCode: "Send verification code",
  Emailvalid: "The email address is incorrect",
  twopasswords: "The passwords you entered don't match!",
  pleaseselect: "please select ",
  Pleaseinput: "Please input",
  ReenterPassword: "Re-enter Password",
  changepassword: "Change Password",
  currentpassword: "Current Password",
  newpassword: "New Password",
  Thetwopasswords: " The passwords you entered don't match!",
  confirmpassword: "Confirm Password",
  Confirm: "Confirm",
  Forgetpassword: "Forgot password ?",
  FullName: "Full Name",
  Verifynow: "Verify now",
  contactUs: "Contact us",
  EditProfile: "Edit Profile",
  MyOrders: "My Orders",
  MyAdresses: "My addresses",
  MyWallet: "My Wallet",
  Logout: "Logout",
  Language: "Language",
  Delivery: "Delivery",
  PersonalShopper: "Personal Shopper",
  personalShopper: "Personal Shopper",
  services: "Services",
  footerAboutText: "Chauffeur Brings you Together",
  footerAddress:
    "The 5th Floor of the Business Village Building (B),in  Deira, Office No S84",
  WeAreOnSocialMedia: " We're on social media ",
  Shipping: "Shipping",
  RentCar: "Rent",
  "ShareRequirments[SD3]": " Share your requirements",
  TermsAndCondition: " Terms and Conditions",
  FAQ: " FAQs",
  ReceiverNumber: " Recipient number",
  ComplaintAndSuggestions: "Complaints & Suggestions",
  Help: "Help",
  Transferto: "Transfer to",
  SelectReceiver: " Select the recipient",
  Chat: "Chat",
  searchuser: "Find a User",
  Notification: "Notifications",
  Searchreceiverphone: "Find the recipient's mobile phone",
  NoNotification: "No Notification!",
  startchatwithdriver: " Start chatting to the driver",
  PrivacyPolicy: "Privacy Policy",
  MyProfile: "My Profile",
  CopyrightsReservedFor: "Copyrights Reserved for",
  chauffeurHub: "Chauffeur hub",
  "curentYear[SD4]": "2024",
  Company: "Company",
  Profile: "Profile",
  search: "search",
  startDate: "Start date",
  endDate: "End date",
  about: "about",
  GettoknowChauffeur:
    " Chauffeur is your professional assistant and right hand in completing your errands and carrying out pick-up and delivery tasks. Whether you use the App as an Individual or as a Company, we offer you integrated delivery services and practical solutions that meet your different needs starting from delivering small and personal shipments as well as shopping items to transporting goods and equipment all over Emirates. Chauffeur will not let you down at any time as our services are available 24/7.",
  Chauffeurapp: "Chauffeur App",
  bannerChauffeur:
    "Chauffeur is the UAE's first delivery app that aims to make your life easier and help you get your work done at the lowest cost and fastest speed by providing a reliable delivery experience that is characterised by the highest standards of quality and professionalism, suitable for individuals and businesses alike.",
  chauffeur: "chauffeur",
  learnMore: "Learn more",
  features: "features",
  secureData: "Secure Data",
  speedOfUse: "Speed of use",
  easeOfUse: "Ease of Use",
  secureDataDesc:
    " We provide you with all the services and solutions to suit your different needs.   ",
  speedOfUseDesc:
    " The pick-up and delivery services are on your mark, just order it.",
  "easeOfUseDesc[SD5]":
    " With one click , you can easily get everything you need. ",
  clientsFeedback: " Customer reviews",
  downloadApp: "Download App",
  ourLocation: "Our Location",
  whyChoose: "Why Choose",
  aSatisfiedCustomerIsBestForBusiness:
    " Satisfied customers are better for business",
  team: "Team",
  call: "اتصل",
  ourMail: "Our mail",
  contact: "contact",
  weAreOnSocialMedia: " We're on social media",
  us: "us",
  us2: "us",
  Renting: "Renting ",
  termsAndConditions: "Terms & Conditions",
  visionOf: "Vision",
  missionOf: "Mission",
  privacyPolicy: "Privacy Policy",
  privacy: "privacy",
  policy: "Policy",
  signInTo: "Login to",
  rememberMe: "Remember Me",
  dontHaveAnAccount: "Don't have an account?",
  signUpTo: "Sign up for",
  signUpChauffeurHubError:
    " Email ending with @chauffeurhub.com is not allowed",
  termsAndConditions2: "Terms and conditions",
  acceptChauffeur: "Accept Chauffeur",
  haveAnAccount: "Have an account?",
  consentToThisItemIsRequired: "Consent to this item is required",
  contactMessage:
    " Enter your account Phone to reset the password. Then you will want to receive a verification code. If you have any problem about password reset please contact us",
  verificationCode: "Verification Code",
  shipping: "shipping",
  myOrders: "my orders",
  DoYouWantToCancelThisOrder: "Would you like to cancel this order?",
  ok: "Yes",
  cancel: "cancel",
  deliveryService: "Delivery Service",
  LE: "AED",
  SAR: "AED",
  from: "from",
  to: "to",
  deliveryInformation: "Delivery information",
  delivery: "delivery",
  orderInfo: "Order info",
  deliveryType: "Delivery Type",
  products: "Products",
  deliveryDate: "Delivery Date",
  deliveryNotes: "Delivery Notes",
  deliveryCost: "Delivery Cost",
  VAT: "Service Fees",
  subTotal: "Subtotal",
  total: "Total",
  Blogs: "Blogs",
  wtsText: "How can I help you?",
  Individual: "Individual",
  Corporate: "Corporate",
  ChauffeurDriver: "Chauffeur",
  foundChauffeurlist: "This Number not found in Chauffeur list.",
  trackDelivery: "Track Delivery",
  proceedToPayment: "Proceed To Payment",
  packageSize: "Package Size",
  categoryType: "Category Type",
  SendSMS: "Send SMS",
  doYouWantToDeleteThisAddress: " Do you want to delete this address?",
  myAddresses: "my addresses",
  locationInfo: "Location info",
  addNewAdress: "Add New Adress",
  removeAdress: "Remove Address",
  orderDelivery: "Order Delivery",
  deliveryCompany: "Delivery Company",
  paymentOwner: "Payment Owner",
  edit: "edit",
  confirm: "Confirm",
  chargeWallet: "Charge wallet",
  charge: "Charge",
  wallet: "Wallet",
  quantity: "quantity",
  cardHolderName: "Card holder name",
  AED: "AED",
  chauffeurCard: "Chauffeur Card",
  bank: "Bank",
  paymentMethod: "Payment Method",
  selectPayment: "Select Payment",
  method: "Method",
  onlinePayment: "Online Payment",
  addProduct: "add",
  Amount: "Amount",
  OopsAddress: "Oops.. No Address found!",
  deliveryLocation: "Delivery Location",
  package: "package",
  size: "Size",
  Code: "Code",
  type: "type",
  product: "Product",
  name: "name",
  count: "count",
  otherProduct: "Other product",
  startDeliver: "Start Deliver",
  enter: "enter",
  address: "address",
  category: "category",
  time: "time",
  callUs: "Call Us",
  nocompanyfound:
    "No offers from delivery companies were found for this order.",
  Asanindividualorcompany:
    " Whether you're an individual or a business, Chauffeur offers reliable, cost-effective and effortless shipping and delivery services",
  comingsoon: "coming soon",
  EnteryourOrderiD: "Enter your Order ID",
  ReceiptImage: "Receipt Image",
  notes: "Notes",
  orderName: "Order name",
  UploadReceiptImage: "Upload Receipt Image",
  itemName: "item name",
  NotpaidOrder: "Not paid Order",
  paidOrder: "paid Order",
  privacyDesc:
    "We deeply care about your privacy and safety. Therefore, we gather some information to be used via the website in order to offer online services through the website. The website will record and track your use of the website using, including but not limited to, text files and other tracking tools and devices. The use of such information is subject to the terms and conditions of the applicable Privacy Policy. The Privacy Policy is an integral part of the terms and conditions stipulated herein and shall be fully read along with it. In case of your non-acceptance of the terms and conditions of the Privacy Policy, you must stop using Chauffeur website and application. We hereby acknowledge our compliance with all privacy protection legislations once issued regarding any personal information we collect. You may be requested to enter information about yourself on different pages of Chauffeur website and application, and we pledge to not use such information for any purpose other than the purposes listed in the Privacy Policy and the purposes determined by each website and application where such information is required. The Privacy Policy governs the way the “Company” collects, uses, keeps, and discloses information collected from the users of the website. This Privacy Policy also applies to the application and all the services and products offered by the Company.",
  list1:
    " We adopt the best quality standards to ensure you get the best service",
  list2:
    " We connect you with the best shipping companies that offer reliable services",
  list3:
    " We listen carefully to understand your needs and professionally assist you in resolving your issues. ",
  list4: "Our team welcomes your enquiries and provides 24/7 support.  ",
  GettoknowChauffeur2:
    " With a simple click on Chauffeur App, you can have all the services you need. We work to make you feel safe and untroubled. So, we combined all the services you may need in one place with the highest standards of quality, credibility, and punctuality. Your safety and peace of mind are our main priority in Chauffeur. ",
  enterCaptalized: "Enter",
  addressCaptalized: "Address",
  CouponCode: "Coupon Code",
  EnterCouponCode: "Enter Coupon Code",
  Apply: "Apply",
  Discount: "Discount",
  CashOnDelivery: "Cash On Delivery (Extra 10 AED)",
  blogsDescription:
    "Dive into our blog for the best insights on professional delivery services and corporate delivery apps in UAE. Your go-to guide for Express delivery services.",
  contactUsTitle: "Contact Us for Chauffeur App Delivery in UAE",
  aboutUsTitle:
    "Chauffeur hub | Courier and delivery app UAE | Fast and secure delivery UAE",
  contactUsDescription:
    "Get in touch with us for any inquiries about our chauffeur app delivery services in UAE. We're here to assist you with prompt support!",
  aboutUsDescription:
    "Discover Chauffeur Hub for fast and secure delivery in UAE. The leading courier and delivery services with tracking. Top delivery app for busniesses.",
};
